import { Component, Inject, HostBinding, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
   selector: 'app-progress',
   templateUrl: './progress.component.html'
})
export class ProgressComponent {

}
