<app-search></app-search>

<div class="box">
   <div *ngIf="transaction">
      <div class="grid-hash">
         <div>
            <span class="grid-hash-left"><i class="fas fa-hashtag"></i></span>
            <span class="grid-hash-middle breakable">{{transaction.transactionId}}</span>
            <span class="grid-hash-right">
               <div class="grid-double">
               </div>
            </span>
         </div>
      </div>
   </div>
</div>

<div class="box">

   <a class="link block-details-toggle" (click)="toggleDetails()"><span *ngIf="!detailsVisible">View</span><span
         *ngIf="detailsVisible">Hide</span>
      details</a>

   <h3><i class="fas fa-receipt"></i>&nbsp;&nbsp;Transaction Details</h3>

   <app-progress class="centered" *ngIf="!transaction"></app-progress>
   <app-error class="centered" [error]="error"></app-error>

   <div class="grid-label-value" *ngIf="transaction">
      <div *ngIf="transaction.timestamp !== 0">
         <span>Age</span>
         <span>{{transaction.timestamp | ago}}</span>
      </div>
      <div *ngIf="transaction.timestamp !== 0">
         <span>Date</span>
         <span>{{transaction.timestamp | timestamp}}</span>
      </div>
      <div *ngIf="transaction.blockIndex">
         <span>Block</span>
         <span><a [routerLink]="['../../block', transaction.blockIndex]">{{transaction.blockIndex}}</a></span>
      </div>
      <div *ngIf="!transaction.blockIndex">
         <span>Block</span>
         <span><a [routerLink]="['../../mempool']">Mempool</a></span>
      </div>
      <div>
         <span>Confirmations</span>
         <span>{{transaction.confirmations}}</span>
      </div>
      <div *ngIf="transaction.isCoinbase">
         <span>Type</span>
         <span>CoinBase</span>
      </div>
      <div *ngIf="transaction.isCoinstake">
         <span>Type</span>
         <span>CoinStake</span>
      </div>
      <div *ngIf="detailsVisible">
         <span>Unit Timestamp</span>
         <span>{{transaction.timestamp}}</span>
      </div>
      <div *ngIf="detailsVisible">
         <span>LockTime</span>
         <span>{{transaction.lockTime}}</span>
      </div>
      <div *ngIf="detailsVisible">
         <span>Replace by Fee</span>
         <span>{{transaction.rbf | yes}}</span>
      </div>
      <div *ngIf="detailsVisible">
         <span>Version</span>
         <span>{{transaction.version}}</span>
      </div>

      <div *ngIf="detailsVisible">
         <span>Transaction fee</span>
         <span>{{transaction.fee | amount}}</span>
      </div>
      <div *ngIf="detailsVisible">
         <span>Size</span>
         <span>{{transaction.size | size}}</span>
      </div>
      <div *ngIf="detailsVisible">
         <span>Virtual size</span>
         <span>{{transaction.virtualSize | size}}</span>
      </div>
      <div *ngIf="detailsVisible">
         <span>HasWitness</span>
         <span>{{transaction.hasWitness | yes}}</span>
      </div>

      <div *ngIf="transaction.hasContract">
         <span>View Smart Contract</span>
         <span><a
               [routerLink]="['../../','contract-transaction', transaction.transactionId]">{{transaction.transactionId |
               slice:0:20}}</a></span>
      </div>
   </div>
</div>

<div class="grid-double" *ngIf="transaction">
   <div class="grid-double-left shadow">
      <h3>Inputs ({{transaction.inputs.length}})</h3>

      <div class="grid-list-inputs" *ngFor="let item of transaction.inputs">

         <div *ngIf="item.inputTransactionId != '0000000000000000000000000000000000000000000000000000000000000000'">
            <a [routerLink]="['../../transaction', item.inputTransactionId]" title="Previous output">
               <i class="fas fa-arrow-left"></i>
            </a>
         </div>
         <div *ngIf="item.inputTransactionId == '0000000000000000000000000000000000000000000000000000000000000000'">
            <i title="Coinbase" class="fas fa-hammer"></i>
         </div>
         <div class="left number"> {{item.inputIndex}}</div>
         <div class="left" *ngIf="item.inputAddress != null">
            <a class="address" tippy tippyType="address"
               [routerLink]="['../../address', item.inputAddress]">{{item.inputAddress}}</a>


         </div>
         <div class="left" *ngIf="item.inputAddress == null && transaction.isCoinbase">
            No inputs (newly generated coins)
         </div>
         <div class="left" *ngIf="item.inputAddress == null && !transaction.isCoinbase">Nonstandard</div>
         <div class="number right"><span [innerHTML]="item.inputAmount | amountHtml"></span><span
               class="symbol hide-small">{{setup.Chain.Symbol}}</span></div>

         <div *ngIf="detailsVisible" class="details-box">
            <div class="grid-label-value">
               <div>
                  <span>Witness</span>
                  <span class="breakable">{{item.witScript}}</span>
               </div>
               <div>
                  <span>ScriptSig (ASM)</span>
                  <span class="breakable">{{item.scriptSigAsm}}</span>
               </div>
               <div>
                  <span>ScriptSig (HEX)</span>
                  <span class="breakable">{{item.scriptSig}}</span>
               </div>
               <div
                  *ngIf="item.inputTransactionId != '0000000000000000000000000000000000000000000000000000000000000000'">
                  <span>Input TX ID</span>
                  <span class="breakable">
                     <a [routerLink]="['../../transaction', item.inputTransactionId]"
                        title="Input">{{item.inputTransactionId}}</a>
                  </span>
               </div>
               <div>
                  <span>SequenceLock</span>
                  <span>{{item.sequenceLock}}</span>
               </div>
            </div>
         </div>


      </div>

   </div>
   <div class="grid-double-right shadow">
      <h3>Outputs ({{transaction.outputs.length}})</h3>

      <div class="grid-list-outputs" *ngFor="let item of transaction.outputs">
         <div class="left number"> {{item.index}}</div>
         <div class="left" *ngIf="item.address != null"><a class="address" tippy tippyType="address"
               [routerLink]="['../../address', item.address]">{{item.address}}</a></div>
         <div class="left" *ngIf="item.address == null && transaction.isCoinbase">Coinbase TX</div>
         <div class="left" *ngIf="item.address == null && transaction.isCoinstake">Coinstake TX</div>
         <div class="left" *ngIf="item.address == null && !transaction.isCoinbase && !transaction.isCoinstake">
            Nonstandard</div>
         <div class="number"><span [innerHTML]="item.balance | amountHtml"></span><span class="symbol hide-small">{{setup.Chain.Symbol}}</span></div>
         <div *ngIf="item.spentInTransaction != null"><a [routerLink]="['../../transaction', item.spentInTransaction]"
               title="Spent"><i class="fas fa-arrow-right"></i></a></div>
         <div *ngIf="item.spentInTransaction == null"></div>

         <div *ngIf="detailsVisible" class="details-box">
            <div class="grid-label-value">
               <div>
                  <span>Type</span>
                  <span>{{item.outputType}}</span>
               </div>
               <div>
                  <span>ScriptPubKey (ASM)</span>
                  <span class="breakable">{{item.scriptPubKeyAsm}}</span>
               </div>
               <div>
                  <span>ScriptPubKey (HEX)</span>
                  <span class="breakable">{{item.scriptPubKey}}</span>
               </div>
               <div *ngIf="item.outputType == 'TX_NULL_DATA'">
                  <span>Data</span>
                  <span class="breakable">{{parseOpreturn(item.scriptPubKey)}}</span>
               </div>
               <div>
                  <span>Spending TX</span>
                  <span class="breakable">
                     <div *ngIf="item.spentInTransaction == null">Unspent</div>
                     <div *ngIf="item.spentInTransaction != null">
                        Spent by <a [routerLink]="['../../transaction', item.spentInTransaction]"
                                    title="Spent">{{item.spentInTransaction}}</a>
                     </div>
                  </span>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>
