<app-search></app-search>

<div class="box">
   <div *ngIf="address">
      <div class="grid-hash">
         <div>
            <span class="grid-hash-left"><i class="fas fa-hashtag"></i></span>
            <span class="grid-hash-middle breakable">{{address}}</span>
            <span class="grid-hash-right">
               <div class="grid-double">
               </div>
            </span>
         </div>
      </div>
   </div>
</div>

<div class="box">

   <h3><i class="fas fa-wallet"></i>&nbsp;&nbsp;Address</h3>

   <app-progress class="centered" *ngIf="!balance"></app-progress>

   <div *ngIf="error">
      <span class="muted">Error: </span> <span class="negative">{{error.title}}</span><br><br>
      {{error.errors | json}}
   </div>

   <div class="grid-label-value-label" *ngIf="balance">
      <div>
         <span>Balance</span>
         <span class="number">{{balance.balance | amount}}</span>
         <span></span>
      </div>
      <div>
         <span>Total Received</span>
         <span class="number">{{balance.totalReceived | amount}}</span>
         <span class="number">#{{balance.totalReceivedCount | number}}</span>
      </div>
      <div *ngIf="balance.totalStake > 0">
         <span>Total Staked</span>
         <span class="number">{{balance.totalStake | amount}}</span>
         <span class="number">#{{balance.totalStakeCount | number}}</span>
      </div>
      <div *ngIf="balance.totalMine > 0">
         <span>Total Mined</span>
         <span class="number">{{balance.totalMine | amount}}</span>
         <span class="number">#{{balance.totalMineCount | number}}</span>
      </div>
      <div>
         <span>Total Sent</span>
         <span class="number">{{balance.totalSent | amount}}</span>
         <span class="number">#{{balance.totalSentCount | number}}</span>
      </div>
      <div *ngIf="balance.pendingReceived > 0">
         <span>Pending Received</span>
         <span class="number">{{balance.pendingReceived | amount}}</span>
         <span></span>
      </div>
      <div *ngIf="balance.pendingSent > 0">
         <span>Pending Sent</span>
         <span class="number">{{balance.pendingSent | amount}}</span>
         <span></span>
      </div>
   </div>
</div>

<div class="box">
   <h3>Transactions ({{total}})</h3>

   <app-progress class="centered" *ngIf="!transactions">Loading transactions...</app-progress>

   <div *ngIf="errorTransactions">
      <span class="muted">Error: </span> <span class="negative">{{errorTransactions.title}}</span><br><br>
      {{errorTransactions.errors | json}}
   </div>

   <div *ngIf="transactions">

      <div class="scrollable address-transactions-scrollable" appDetectScroll (onScroll)="onScroll($event)" [bottomOffset]="400" [topOffset]="400">
         <div class="grid-list-transactions" *ngFor="let item of transactions">
            <i *ngIf="item.entryType == 'stake'" class="fas fa-coins" title="Stake"></i>
            <i *ngIf="item.entryType != 'stake'" class="fas fa-handshake" title="Transfer"></i>
            <span class="left"><a class="nonbreakable number" [routerLink]="['../../transaction', item.transactionHash]">{{item.transactionHash}}</a></span>
            <span> <a class="number" [routerLink]="['../../block', item.blockIndex]">{{item.blockIndex}}</a></span>
            <span class="number">{{item.value | amount}} {{setup.Chain.Symbol}}</span>
         </div>
         <br>
         <app-progress class="centered" *ngIf="loading">Loading more transactions...</app-progress>
      </div>
   </div>

</div>
