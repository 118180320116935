<app-search></app-search>



<div class="box">

   <h3><i class="fas fa-receipt"></i>&nbsp;&nbsp;Smart Contracts By Type</h3>

</div>

<div class="box">

   <h3>{{contractType}} ({{total}})</h3>

   <app-progress class="centered" *ngIf="!transactions">Loading transactions...</app-progress>

   <div *ngIf="errorTransactions">
      <span class="muted">Error: </span> <span class="negative">{{errorTransactions.title}}</span><br><br>
      {{errorTransactions.errors | json}}
   </div>




   <div *ngIf="transactions">

      <div class="scrollable address-transactions-scrollable" appDetectScroll (onScroll)="onScroll($event)" [bottomOffset]="400" [topOffset]="400">
         <div class="grid-list-contract-listbytype" *ngFor="let item of transactions">


            <span class="left">{{item.contractCodeType}}</span>
            <span class="left"><a class=" number" [routerLink]="[navPath,'contract-transaction', item.transactionId]">{{item.transactionId | slice:0:20}}</a></span>
            <span class="left"> <a class="number" [routerLink]="[navPath, 'block', item.blockIndex]">{{item.blockIndex}}</a></span>
            <span class="left"> <a class="number" [routerLink]="[navPath, 'contract-address', item.contractAddress]">{{item.contractAddress}}</a></span>

         </div>
         <br>
         <app-progress class="centered" *ngIf="loading">Loading more transactions...</app-progress>
      </div>
   </div>

</div>


