<div class="widget" *ngIf="setup.featureEnabled(setup.Explorer?.Features?.Ticker)">
   <h1><span class="accent">${{setup.Chain?.Symbol}}</span>.</h1>
   <h2 *ngIf="ticker.btc">
      {{ticker.btc.toFixed(8)}} <i class="fab fa-btc"></i> <span *ngIf="ticker.changePercentage" class="{{ticker.changeType}}">{{ticker.changePercentage}}%</span>
   </h2>
   <h3 *ngIf="ticker.usd">${{ticker.usd.toFixed(3)}}</h3>
   <app-error class="centered" [error]="error"></app-error>
</div>

<div class="widget">
   <p>
      <a class="link" [routerLink]="['explorer']">Go to explorer</a>
      <a *ngIf="setup.featureEnabled(setup.Explorer?.Features?.Ticker)" [href]="setup.Explorer?.Ticker?.WebUrl" class="link">Price details</a>
   </p>
</div>