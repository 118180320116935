<h1>API</h1>
<p>
  This block explorer relies on the API exposed by a blockchain indexer.
</p>
<p>
  If you'd like to call this API directly, you can read the documentation for here:
</p>
<p *ngIf="docUrl">
  <a class="link" [href]="docUrl">{{docUrl}}</a>
</p>

<br>
<br>
<br>

<h2>Configure API</h2>
<p>
  For better privacy or in case the default api is offline you can specify a different api url for the indexer.
  <Br>
  Or alternatively discover an indexer from the list of supported blockcore DNS servers.
</p>

<input id="input-api-link" class="input-search box" type="text" (keyup.enter)="save()"  placeholder="api endpoint." [(ngModel)]="inputUrl">
<p>
  <a class="link" (click)="discover()">Discover</a>
  <a class="link" (click)="reset()" style="margin: 0 20px 0 20px">Reset</a>
  <a class="link" (click)="save()">Save</a>
</p>
<br>

<div *ngIf="errorMessage">
  <span class="muted">Error: </span> <span class="negative">{{errorMessage.title}}</span><br><br>
  {{errorMessage.errors | json}}
</div>

<div *ngIf="indexers">
  <h4>Discovered Indexers</h4>
  <div>
     <!-- <div class="grid-list-configure-api" *ngFor="let items of endpoints"> -->
      <!-- <span class=""><strong>{{items.Source}}</strong></span> -->
      <div class="grid-list-configure-api-inner" *ngFor="let indexer of indexers">
        <span class=""><a class="link" (click)="saveDomain(indexer)" >{{indexer.domain}}</a></span>
        <span *ngIf="indexer.online == true" class="">online</span>
        <span *ngIf="indexer.online != true" class="">offline</span>
      </div>
     <!-- </div> -->
     <app-progress class="centered" *ngIf="loading">Loading more domains...</app-progress>
     <div class="dimmed-notification">Clicking on the indexer will automatically change and save.</div>
  </div>
</div>