<div class="box">
   <h3>Richlist</h3>

   <app-progress class="centered" *ngIf="!addresses">Loading richlist...</app-progress>

   <div *ngIf="addresses">
      <div class="grid-header richlist">
         <span>Rank</span>
         <span>Address</span>
         <span class="right">Balance</span>
      </div>

      <div class="scrollable" appDetectScroll (onScroll)="onScroll($event)" [bottomOffset]="400" [topOffset]="400">
         <div class="grid grid-odd-line richlist" *ngFor="let item of addresses; index as i">
            <span class="center">{{i+1}}<!--<span><i class="fas fa-info-circle"></i></span>--></span>
            <span class><a [routerLink]="['../../explorer/address/', item.address]">{{item.address}}</a>
            </span>
            <span class="right number" [innerHTML]="item.balance | amountHtml"></span>
         </div>
         <br>
         <app-progress class="centered" *ngIf="loading">Loading richlist...</app-progress>
      </div>
   </div>

</div>