<app-search></app-search>

<div class="box">
   <div *ngIf="daoContract">
      <div class="grid-hash">
         <div>
            <span class="grid-hash-left"><i class="fas fa-hashtag"></i></span>
            <span class="grid-hash-middle breakable">{{daoContract.contractAddress}}</span>
            <span class="grid-hash-right">
               <div class="grid-double">
               </div>
            </span>
         </div>
      </div>
   </div>
</div>

<div class="box">

   <h3><i class="fas fa-receipt"></i>&nbsp;&nbsp;DAO Contract Details</h3>

   <app-progress class="centered" *ngIf="!daoContract"></app-progress>
   <app-error class="centered" [error]="error"></app-error>

   <div class="grid-label-value" *ngIf="daoContract">
      <div *ngIf="daoContract">
         <span>Available amount</span>
         <span>{{daoContract.currentAmount | amount}} {{setup.Chain.Symbol}}</span>
      </div>
      <div *ngIf="daoContract.minVotingDuration">
         <span>Min voting duration</span>
         <span>{{daoContract.minVotingDuration}}</span>
      </div>
      <div *ngIf="daoContract.maxVotingDuration">
         <span>Max voting duration</span>
         <span>{{daoContract.maxVotingDuration}}</span>
      </div>
      <div>
         <span>Created on Transaction</span>
         <span><a [routerLink]="['../../','contract-transaction', daoContract.contractCreateTransactionId]">{{daoContract.contractCreateTransactionId | slice:0:20}}</a></span>
      </div>
      <div *ngIf="totalDepositsAmount">
         <span>Total deposits amount</span>
         <span>{{totalDepositsAmount | amount}} {{setup.Chain.Symbol}}</span>
      </div>
      <div *ngIf="totalDepositsAmount">
         <span>Amounts on approved proposals</span>
         <span>{{totalAmountOnApprovedProposals | amount}} {{setup.Chain.Symbol}}</span>
      </div>

      <div *ngIf="daoContract.error">
         <span>Error</span>
         <span>{{daoContract.error}}</span>
      </div>

      <div>
      </div>

   </div>
</div>

<div class="box">
   <h3>Deposits</h3>

   <div *ngIf="deposits" class="left">
      <h3>Deposits</h3>

      <div >
         <div class="grid grid-odd-line" *ngFor="let item of deposits">
            <span class="address left">{{item.senderAddress}}</span>
            <span class="right">{{item.amount | amount}}</span>
            <span><a [routerLink]="['../../','contract-transaction', item.transactionId]">{{item.transactionId | slice:0:20}}</a></span>

         </div>
      </div>
   </div>
</div>

<div class="box">
   <h3>Proposals</h3>

   <div *ngIf="proposals" class="left">
      <div>
         <div class="grid-list-contract-dao-proposals grid grid-odd-line" *ngFor="let item of proposals">
            <span class="left">{{item.description}}</span>
            <!--<span class="left">Start block: {{item.proposalStartedAtBlock}}</span>
            <span class="left">End block: {{item.proposalCompletedAtBlock}}</span>-->
            <!--<span><a [routerLink]="['../../','address', item.recipient]">{{item.recipient  | slice:0:20}}</a></span>-->
            <span><a [routerLink]="['../../','contract-transaction', item.payoutTransactionId]">{{item.payoutTransactionId | slice:0:20}}</a></span>
            <span class="right">{{item.amount| amount}}</span>
            <span class="centered" *ngIf="!item.wasProposalAccepted">rejected</span>
            <span class="centered" *ngIf="item.wasProposalAccepted">accepted</span>
         </div>
      </div>
   </div>

</div>
