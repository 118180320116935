<app-search></app-search>

<div class="box">
   <div *ngIf="transaction">
      <div class="grid-hash">
         <div>
            <span class="grid-hash-left"><i class="fas fa-hashtag"></i></span>
            <span class="grid-hash-middle breakable">{{transaction.contractHash}}</span>
            <span class="grid-hash-right">
               <div class="grid-double">
               </div>
            </span>
         </div>
      </div>
   </div>
</div>

<div class="box">

   <!--<a class="link block-details-toggle" (click)="toggleDetails()"><span *ngIf="!detailsVisible">View</span><span
         *ngIf="detailsVisible">Hide</span>
      details</a>-->

   <h3><i class="fas fa-receipt"></i>&nbsp;&nbsp;{{transaction.codeType}}</h3>

   <app-progress class="centered" *ngIf="!transaction"></app-progress>
   <app-error class="centered" [error]="error"></app-error>

   <div class="grid-label-value" *ngIf="transaction">
  
    
    

   
      <div>
         <span>Contract Address</span>
         <span><a [routerLink]="['../../contract-address', address]">{{address}}</a></span>
      </div>

   </div>
</div>

<div *ngIf="transaction" class="box left">
   <h3>Source Code</h3>

   <!--<ngx-prism class="p-0" [language]="'csharp'" [code]="{{transaction.sourceCode}}">
   </ngx-prism>-->

   <span><pre style="max-width: 1270px; overflow: auto;">{{transaction.sourceCode}}</pre></span>

</div>




